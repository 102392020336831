.account-staking {
    padding-top: 75px;
}

.account-staking .stake-allocation-section .ellipse18 {
    top: 21%;
}

.account-staking__stake-block {
    justify-content: space-between;
}

.stake-block .tooltip-wrapper {
    margin-left: 8px;
}

.stake-block__info .info-list {
    margin-bottom: 32px;
}

.stake-block__info .info-list__item {
    border: 1px solid var(--color-separator);
    border-radius: 26px;
    padding: 18px 32px;
}

.stake-block__info .info-list__item .value {
    font-size: 40px;
}

.account-staking__stake-block .balance-item {
    margin-bottom: 32px;
}

.account-staking__stake-block .balance-item__main {
    font-size: 26px;
}

.stake-form__heading {
    display: flex;
    align-items: center;
}

.stake-form__heading > div {
    margin-left: 12px;
}

.stake-form__heading p {
    margin-bottom: 3px;
}

.stake-form__heading .round-button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    padding: 5px;
}

.stake-form__heading {
    margin-bottom: 24px;
}

.account-staking .stake-form {
    height: 560px;
}

.stake-form .form-message {
    margin-bottom: auto;
}

.stake-form .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.stake-block-tab-unstake .stake-block__buttons {
    flex-wrap: wrap;
}

.stake-block-tab-unstake .stake-block__buttons .round-button {
    flex-basis: 100%;
}

.staking-stats {
    margin-top: 14px;
}

.staking-stats.info-list > div + div {
    margin-top: 24px;
}
