.wallet-page {
  text-align: left;
}

.wallet-page .hero-row {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left !important;
}

.wallet-page .hero-row .title {
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 112%;
  color: #fff;
  text-align: left;
  padding-bottom: 30px;
}

.wallet-page .connect-row {
  position: relative;
  justify-content: center;
  padding: 80px 0;
}

.wallet-page .connect-row .column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wallet-page .connect-row .round-button {
  margin-top: 32px;
}

.wallet-page .connect-row .tile__main {
  margin-top: 60px;
}

.wallet-page .connect-row .icon {
  position: absolute;
  top: -75px;
}

.wallet-page .tile {
  position: relative;
}
