@import url(https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap);

.card.accordion span {
    display: block;
}

.card.accordion {
    font-size: 18px;
    background: #23282F;
    box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    margin-bottom: 1rem;
    text-align: left;
}

.card.accordion .collapse {
    padding-top: 0;
    line-height: 150%;
}

.card.accordion .card-header {
    border: 0;
    padding: 0;
}

.card.accordion .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100% !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-text-white);
    padding: 32px 36px;
}

.card.accordion .btn span {
    transition: 0.3s linear;
}

.card.accordion .btn.active {
    color: var(--color-text-violet);
}

.card.accordion .btn.active svg path {
    fill: var(--color-text-violet);
}

.card.accordion .btn.active span {
    transform: rotate(180deg);
}

.card.accordion .card-body {
    padding: 0 8rem 2.25rem 2.25rem;
}

.navbar-brand svg {
  height: 60px;
}

.nav-link {
  position: relative;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-text-white);
}

.nav-link:hover {
  color: var(--color-text-violet) !important;
}

.nav-link.active {
  border: none;
}

.nav-link.active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background-color: var(--color-text-violet);
  border-radius: 2px;
}

.center-items {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

nav {
  padding: 0 10%;
}

nav .btn {
  background: var(--color-text-white);
  border-radius: 50px;
  line-height: 38px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@supports (gap: 24px) {
  .navbar-collapse {
    grid-gap: 24px;
    gap: 24px;
  }
}

@supports not (gap: 24px) {
  .navbar-collapse > * + * {
    margin-left: 24px;
  }
}

@media (max-width: 1199px) {
  .navbar .nav-link {
    padding: 0.5rem;
  }
}

.round-button {
    position: relative;
}

.round-button, .btn.round-button {
    font-family: Archivo !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: var(--color-text-white);
    color: #181A1B;
    border: 0;
    border-color: var(--color-text-white);
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
}

.round-button svg {
    height: 20px;
    margin-left: 5px;
}

.round-button .link-arrow {
    height: 1.2em;
    width: 1.2em;
}

.round-button .link-arrow path {
    fill: #181A1B;
    transition: .15s;
}

.round-button a, .btn.round-button a {
    color: inherit;
    text-decoration: none;
}

.round-button a:hover, .btn.round-button a:hover {
    color: inherit;
    text-decoration: none;
}

.round-button.dark {
    background: rgba(255, 255, 255, 0.15);
    color: var(--color-text-white);
    border-color: rgba(255, 255, 255, 0.15);
}

.round-button.dark .link-arrow path,
.round-button.transparent .link-arrow path {
    fill: var(--color-text-white);
}

.round-button.transparent {
    color: var(--color-text-white);
    background: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
}

.round-button:not(:disabled):hover, .btn.round-button:not(:disabled):hover {
    color: var(--color-text-violet);
}

.round-button:not(:disabled):hover .link-arrow path {
    fill: var(--color-text-violet);
}

.round-button.large {
    border-radius: 30px;
    line-height: 46px;
    padding-left: 30px;
    padding-right: 30px;
}

.round-button.small {
    line-height: 34px;
    padding-left: 16px;
    padding-right: 16px;
}

.round-button.wide {
    padding-left: 66px;
    padding-right: 66px;
}

@media (max-width: 1399px) {
    .round-button, .btn.round-button {
        font-size: 14px;
        line-height: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .round-button.large {
        line-height: 42px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .round-button.wide {
        padding-left: 55px;
        padding-right: 55px;
    }

    .round-button.small {
        line-height: 28px;
    }
}

.btn.round-button:disabled {
    pointer-events: auto;
    cursor: not-allowed;
}

.round-button.round-button--with-badge:disabled {
    opacity: 1;
}

.round-button__badge {
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 12px;
    line-height: 1.26;
    color: var(--color-background);
    padding: 6px 12px;
    background-color: var(--color-text-white);
    border: 2px solid var(--color-background);
    border-radius: 20px;
    text-transform: none;
    transform: rotate(-3.93deg);
}

.project-tag {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: 500;
    line-height: 126%;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.project-tag__image {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

@media (max-width: 1199px) {
    .project-tag.badge {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.project-tag.coming-soon {
    background: linear-gradient(91.53deg, #29e6dc 1.3%, #dd976f 98.7%);
}

.project-tag.registration-open {
    background: linear-gradient(91.53deg, #a4b50b 1.3%, #8dc802 98.7%);
}

.project-tag.registration-closed {
    background: linear-gradient(91.53deg, #8d0bb5 1.3%, #48505C 98.7%);
}

.project-tag.private-open {
    background: linear-gradient(42.26deg, #386865 33.95%, #9946fe 138.93%);
}

.project-tag.open {
    background: linear-gradient(90deg, #46fe4f 0%, #67b12b 100%);
}

.project-tag.closed {
    background: linear-gradient(90deg, #851010 0%, #720e0e 100%);
}

.project-info__head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;
}

.project-info__logo {
    width: 80px;
    height: 80px;
    margin-right: 24px;
}

.project-info__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

@supports (gap: 8px) {
    .project-info__tags {
        grid-gap: 8px;
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .project-info__tags > * + * {
        margin-left: 8px;
    }
}

.project-info__name {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
}

.project-info__subtitle {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    margin-bottom: 0;
}

.project-info__description {
    line-height: 1.5;
    letter-spacing: 1px;
}

.project-info__download-terms {
    display: block;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 1px;
    margin: 32px 0;
}

.project-info__links {
    display: flex;
    align-items: center;
}

@supports (gap: 12px) {
    .project-info__links {
        grid-gap: 12px;
        gap: 12px;
    }
}

@supports not (gap: 12px) {
    .project-info__links > * + * {
        margin-left: 12px;
    }
}

.project-info__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 50%;
    transition: .2s linear;
}

.project-info__link svg {
    width: 20px;
    height: 20px;
}

.project-info__link:hover {
    background: rgba(255, 255, 255, 0.35);
}

@media(max-width: 1199px) {
    .project-info__head {
        margin-bottom: 24px;
    }

    .project-info__logo {
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }

    .project-info__name {
        font-size: 18px;
    }
}

.project-stats.tile {
  padding: 40px;
}

.project-stats__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.project-stats__status h3.title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.26;
}

.project-stats__progress {
  margin-bottom: 16px;
}

.project-stats__progress .progress {
  background: rgba(255, 255, 255, 0.2);
  overflow: unset;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 10px;
}

.project-stats__progress .progress-bar {
  background: linear-gradient(89.94deg, #32595c 0.05%, #19caa4 99.95%);
  box-shadow: 0px 0px 20px rgba(61, 84, 194, 0.5);
  border-radius: 10px;
}

.project-stats__progress .percentage {
  display: block;
  text-align: right;
  font-size: 20px;
  line-height: 1.26;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 7px;
}

.project-stats__list {
  margin-bottom: 24px;
}

@media (max-width: 1400px) {
  .project-stats__list {
    font-size: 16px;
  }
}

.project-stats__list .value {
  white-space: nowrap;
}

.project-stats__info {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: 1px;
}

.project-stats__info dt {
  font-weight: 400;
  color: var(--color-text-white-transparantize);
}

.project-stats__info dd {
  font-weight: 600;
  margin-bottom: 0;
}

.stats-block {
  padding: 16px 24px;
  text-align: center;
  /* border: 1px solid var(--color-separator); */
  flex-basis: calc(50% - 8px);
  border-radius: 24px;
}

.stats-block dt {
  margin-bottom: 6px;
}

.stats-block--double {
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-block--double .separator {
  height: 100%;
  width: 1px;
  margin: 0 24px;
  background-color: var(--color-separator);
}

.stats-block--double > div:not(.separator) {
  flex-grow: 1;
}

.apply-white-list {
  display: flex;
  justify-content: center;
}

@media (max-width: 1399px) {
  .project-stats__info {
    font-size: 14px;
  }

  .stats-block {
    padding: 16px;
  }
}

@media (max-width: 1199px) {
  .project-stats__status {
    margin-bottom: 16px;
  }

  .project-stats__status h3.title {
    font-size: 32px;
  }
}

.timer-values {
    display: flex;
    align-items: center;
    justify-content: center;
    --highlight-color: var(--color-text-violet)
}

.timer-values__item {
    margin: 0 1rem;
}

.timer-values__item .value {
    font-size: 32px;
    font-weight: 600;
    line-height: 125%;
    color: var(--highlight-color);
    text-align: center;
}

.timer-values__item.seconds .value {
    color: var(--color-text-white-transparantize);
}

.timer-values__item .name {
    line-height: 144%;
    text-align: center;
    text-transform: capitalize;
}

.project-widget {
    transition: .2s linear;
    height: 100%;
}

.project-widget__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.project-widget:hover {
    background-color: #23282F;
    box-shadow: none !important;
}

.project-widget .project-info {
    margin-bottom: 10px;
}

.project-widget .project-info__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-widget .project-stats {
    margin-bottom: auto;
}

.project-widget__buttons {
    display: flex;
    justify-content: center;
}

@supports (gap: 17px) {
    .project-widget__buttons {
        grid-gap: 17px;
        gap: 17px;
    }
}

@supports not (gap: 17px) {
    .project-widget__buttons > * + * {
        margin-left: 17px;
    }
}

.project-widget__buttons a {
    max-width: calc(50% - 8px);
    flex-grow: 1;
}

.project-widget__buttons .round-button {
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 10px;
}

@media (max-width: 1199px) {
    .project-widget.tile {
        padding: 32px;
    }

    .project-widget .project-info {
        margin-bottom: 24px;
    }

    .project-widget__buttons {
        margin-top: 24px;
    }
}

.projects-slider {
    display: flex;
    padding-left: 12px;
    max-width: var(--container-sticky-max-width);
    margin-left: auto;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.projects-slider__info {
    width: 270px;
    margin-right: 48px;
}

.projects-slider__info .title {
    margin-bottom: 32px;
}

.projects-slider__pagination {
    margin-bottom: 64px;
}

.projects-slider__pagination .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    opacity: 1;
    margin: 0 4px;
    transition: .15s linear;
}

.projects-slider__pagination .swiper-pagination-bullet-active {
    width: 40px;
    background: var(--color-text-white);
}

.projects-slider-slides {
    width: 100%;
}

.projects-slider .swiper-slide {
    width: 392px;
    transition: opacity .15s linear;
}

.projects-slider .swiper-slide:not(.swiper-slide-active, .swiper-slide-next, .swiper-slide-prev) {
    opacity: 0.5;
}

.projects-slider__nav-next {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
}

.projects-slider__nav-next svg path {
    transition: .15s linear;
}

.projects-slider__nav-next:hover svg path {
    fill: var(--color-text-violet);
}

.project-slide {
    width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-slide__head {
    margin-bottom: 16px;
}

.project-slide__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

@supports (gap: 8px) {
    .project-slide__tags {
        grid-gap: 8px;
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .project-slide__tags > * + * {
        margin-left: 8px;
    }
}

.project-slide__tags .project-tag {
    font-weight: 500;
    padding: 4px 8px;
}

.project-slide__name {
    font-size: 40px;
    font-weight: 600;
}

.project-slide__subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;
}

.project-slide__name,
.project-slide__subtitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-slide__image {
    position: relative;
    width: 100%;
    height: 246px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-slide__logo {
    width: 140px;
    height: 140px;
}

.project-slide__logo--nft {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 2;
}

.project-slide__nft-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
}

.project-slide .project-slide__button.round-button {
    position: absolute;
    width: 54px;
    height: 54px;
    bottom: 24px;
    right: 24px;
    border-radius: 50%;
    background-image: url(/static/media/right_arrow.5f2c9f8d.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.project-slide__button.round-button:hover {
    background-color: rgba(0, 0, 0, 0.25);
}

.loader {
    --loader-size: 60px;
}

.lds-ring {
    display: block;
    position: relative;
    width: var(--loader-size);
    height: var(--loader-size);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--loader-size);
    height: var(--loader-size);
    border: 2px solid var(--color-text-violet);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-text-violet) rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2);
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.round-button .loader {
    --loader-size: 24px;
    display: inline-block;
    vertical-align: middle;
}

.loader.centered {
    margin: auto;
}

.timer-wrap {
    position: relative;
}

.timer-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 80;
    background-size: 100%;
    background-position: center;
    background-image: linear-gradient(178.08deg, rgba(196, 196, 196, 0) 1.57%, rgba(196, 196, 196, 0.0067) 13.92%, rgba(196, 196, 196, 0.01) 47.92%, rgba(196, 196, 196, 0.01) 81.29%, rgba(196, 196, 196, 0) 98.38%);
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    .timer-blur {

        -webkit-backdrop-filter: blur(20px);

                backdrop-filter: blur(20px);
    }
}

@supports not ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    .timer-blur {
        background-color: rgba(27, 28, 31, 0.96);
    }
}

.timer-fog {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 90;
}

.timer-wrap .timer {
    max-width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.balance-item {
    display: flex;
    align-items: center;
}

.balance-item__icon {
    width: 90px;
    height: 90px;
    margin-right: 24px;
    padding: 10px;
}

.balance-item__title {
    margin-bottom: 8px;
}

.balance-item .tooltip-wrapper {
    margin-left: 8px;
}

@media (max-width: 1199px) {
    .balance-item__title.tile__description {
        font-size: 16px;
    }

    .balance-item__main.tile__main {
        font-size: 32px;
    }
}

.tooltip-wrapper {
    cursor: pointer;
}

.common-tooltip .tooltip-inner {
    background: linear-gradient(114.09deg, #000000 84.89%, rgba(0, 0, 0, 0) 99.69%);
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(60px);
            backdrop-filter: blur(60px);
    border-radius: 26px;
    padding: 32px;
    max-width: 450px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
}

.wrong-network-modal .modal-dialog {
    max-width: 610px;
}

.wrong-network-modal .modal-content {
    padding: 40px 54px;
}

.wrong-network-modal .modal-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 24px;
}

.wrong-network-modal p {
    line-height: 1.5;
    margin-bottom: 12px;
    letter-spacing: 1px;
}

.wrong-network-modal__buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

@supports (gap: 16px) {
    .wrong-network-modal__buttons {
        grid-gap: 16px;
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .wrong-network-modal__buttons > * + * {
        margin-left: 16px;
    }
}

.marketing-page {
    position: relative;
    padding-top: 60px;
}

.marketing-page.page h1.title {
    margin-bottom: 24px;
}

.marketing-page .background .ellipse19 {
    top: -10%;
}

.marketing-page .background .ellipse20 {
    top: 20%;
}

.marketing-page .background .ellipse23 {
    top: 45%;
}

.form-fill-response {
    max-width: 604px;
    margin: 48px auto;
}

.form-fill-response h2 {
    margin-bottom: 24px;
}

.form-fill-response p {
    margin-bottom: 32px;
}

.marketing-page-form {
    height: calc(100vh - 326px);
    max-width: 604px;
    margin: 48px auto;
}

.marketing-page-form.tile {
    padding: 40px 8px;
    border-radius: 32px;
}

.marketing-page-form__inner {
    max-height: 100%;
    padding: 0 26px 0 32px;
}

.marketing-page-form h2.title {
    margin-bottom: 24px;
}

.marketing-page-form h4.title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 1.12;
}

.marketing-page-form .row + .row {
    margin-top: 32px;
}

.marketing-page-form .form-group {
    margin-bottom: 0;
}

.marketing-page-form .form-group:not(:last-child) {
    margin-bottom: 24px;
}

.marketing-page-form .form-group.form-group--radio:not(:last-child) {
    margin-bottom: 16px;
}

.marketing-page-form__submit-row .loader {
    vertical-align: middle;
}

.marketing-page__text p,
.marketing-page__text li {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
}

.marketing-page__text a {
    color: var(--color-text-violet);
    text-decoration: none;
}

.marketing-page__text a:hover {
    color: var(--color-text-white-transparantize);
}

.marketing-page__text h2.title {
    margin-top: 40px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title {
    margin-top: 32px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title em {
    color: var(--color-text-white-transparantize);
}

.team-card {
    text-align: center;
    padding: 16px 0;
}

.team-card__image-box {
    position: relative;
    width: 160px;
    margin: 0 auto 16px;
}

.team-card__image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    transition: .15s linear;
}

.team-card:hover .team-card__image {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.team-card__logo {
    position: absolute;
    right: -5px;
    bottom: 10px;
}

.team-card__name {
    font-size: 20px;
}

.team-card__bio {
    margin-bottom: 0;
}

.subscription-form__image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    -webkit-filter: drop-shadow(0px 4px 20px rgba(255, 162, 88, 0.35));
            filter: drop-shadow(0px 4px 20px rgba(255, 162, 88, 0.35));
}

.subscription-form {
    max-width: 604px;
    margin: 48px auto;
}

.subscription-form h2.title {
    margin-top: 40px;
}

.subscription-form__description {
    padding: 0 35px;
    margin-bottom: 24px;
}

.subscription-form__submit-button {
    width: 100%;
}

.subscription-form__submit-button .loader {
    vertical-align: middle;
}

.partner-pwd-widget {
    max-width: 604px;
    margin: 10vh auto;
    position: relative;
}

.partner-pwd-widget__logo {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -60px;
    left: calc(50% - 60px);
}

.partner-pwd-widget__title {
    text-align: center;
    margin-top: 34px;
    margin-bottom: 16px;
}

.partner-pwd-widget__description {
    line-height: 1.5;
    letter-spacing: 1px;
}

.partner-pwd-form {
    margin-top: 32px;
}

.partner-pwd-form .round-button {
    width: 100%;
}

.asset-loading {
    position: relative;
}

.asset-loading:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
    z-index: 2;
}

.scroll-to-top {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    bottom: 40px;
    right: 40px;
    background: rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.15s linear;
    transform: translateY(35px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
}

.scroll-to-top.shown {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0);
}

.scroll-to-top svg {
    max-width: 60%;
    height: auto;
    margin: 0 auto;
    transition: all 0.2s;
}

.scroll-to-top:hover svg {
    margin-top: -20px;
}

.portfolio-item.tile {
    padding: 40px 40px 12px;
}

.portfolio-item__main {
    display: grid;
    grid-template-columns: var(--portfolio-columns);
    padding: 0 0 24px;
    grid-gap: 18px;
}

.portfolio-item__col {
    display: flex;
    align-items: center;
}

.portfolio-item__col span:empty {
    position: relative;
    margin-left: 30%;
}

.portfolio-item__col span:empty:before {
    content: '–';
}

@supports (gap: 16px) {
    .portfolio-item__col,
    .portfolio-item__additions {
        grid-gap: 16px;
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .portfolio-item__col > * + *,
    .portfolio-item__additions > * + * {
        margin-left: 16px;
    }
}

.portfolio-item__name {
    margin-bottom: 0;
}

.portfolio-item__name a {
    text-decoration: none;
}

.portfolio-item__logo {
    width: 90px;
    height: 90px;
    margin-right: 8px;
    text-align: center;
}

.portfolio-item__col h4 {
    font-size: 32px;
    font-weight: 600;
}

.portfolio-item__col span {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.portfolio-item__col .round-button {
    white-space: nowrap;
}

.round-button.portfolio-item__toggle {
    padding-left: 13px;
    padding-right: 13px;
}

.portfolio-item__toggle svg {
    padding: 0;
    width: 15px;
    margin-left: 12px;
    transition: .2s linear;
    transform-origin: center;
}

.portfolio-item__toggle.active svg {
    transform: rotate(180deg);
}

.portfolio-item__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.portfolio-item__collapse p {
    margin-right: 56px;
}

.portfolio-item__additions {
    display: flex;
    margin-bottom: 0;
}

.portfolio-item__additions div {
    border: 1px solid var(--color-separator);
    border-radius: 26px;
    text-align: center;
    padding: 16px 20px;
    width: 248px;
    line-height: 1.26;
    letter-spacing: 1px;
}

.portfolio-item__additions dt {
    font-weight: 400;
    color: var(--color-text-white-transparantize);
    margin-bottom: 4px;
}

.portfolio-item__additions dd {
    font-weight: 600;
}

.portfolio-item__footer {
    text-align: center;
    padding: 24px 0 12px;
    border-top: 1px solid var(--color-separator);
}

.portfolio-item__schedule svg {
    margin-right: 12px;
}

.portfolio-list {
    --portfolio-columns: 7fr repeat(2, 3fr) 2.5fr 3fr 4fr;
}

.portfolio-list .no-projects-tile.hidden {
    display: none;
}

.portfolio-list__body:empty + .no-projects-tile.hidden {
    display: flex;
}

.portfolio-list__body > .loader {
    display: block;
    margin: 35px auto 0;
}

.portfolio-list__header {
    list-style: none;
    padding: 16px 40px;
    margin: 0;
    display: grid;
    grid-gap: 18px;
    grid-template-columns: var(--portfolio-columns);
}

.portfolio-list__header li {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    line-height: 1.26;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
}

.portfolio-list .portfolio-item {
    margin-bottom: 24px;
}

.approval-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@supports (gap: 8px) {
    .approval-steps {
        grid-gap: 8px;
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .approval-steps > * + * {
        margin-left: 8px;
    }
}

.approval-steps__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 0.5;
}

.approval-steps__label.active {
    opacity: 1;
}

.approval-steps__line {
    width: 200px;
    height: 4px;
    border-radius: 3px;
    background: linear-gradient(90deg, #FFE68F 0%, #FFC061 33.36%, rgba(255, 144, 41, 0.5) 62.26%, rgba(254, 70, 70, 0) 100.49%);
}

.approval-steps__line.filled {
    background: linear-gradient(90deg, #FFE68F 0%, #FFC061 33.36%, #FF9029 62.26%, #FE4646 100.49%);
}

.whitelist-form .title {
    margin-bottom: 32px;
}

.whitelist-form .round-button {
    margin: 0;
}

.whitelist-form .form-row {
    justify-content: space-between;
}

.whitelist-form .form-row:not(.form-row + .form-row) {
    margin-top: 40px;
}

.whitelist-form .form-row .round-button {
    width: 216px;
    padding: 6px 0;
    flex-shrink: 0;
}

.whitelist-form .form-check {
    margin-right: 12px;
}

.whitelist-form .input-group {
    padding: 16px;
}

.whitelist-form .input-group.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.whitelist-form .input-group-prepend {
    margin-right: 2px;
}

.whitelist-form .input-group .form-control {
    text-align: left;
    font-size: 16px;
}

.whitelist-form .round-button[type="submit"] {
    width: 100%;
    margin-top: 16px;
}

.whitelist-form .round-button[type="submit"] svg {
    margin-top: -2px;
}

.whitelist-form .round-button[type="submit"] svg path {
    fill: var(--color-text-black);
}

@media (max-width: 1399px) {
    .whitelist-form .form-check-label {
        font-size: 14px;
    }
}

.whitelist-status-view {
    flex-grow: 1;
    display: flex;
}

.whitelist-status-view .ellipse21 {
    left: 65%;
}

.whitelist-status-view .tile {
    position: relative;
    text-align: center;
    width: 600px;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.whitelist-status-view .tile__description {
    color: var(--color-text-white);
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
}

.whitelist-status-view .tile__main {
    margin-bottom: 16px;
}

.whitelist-status-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
}

.whitelist-status-content {
    max-width: 330px;
}

.no-projects-tile {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.projects-page .title:first-child {
    position: relative;
    z-index: 110;
}

.projects-open {
    padding-top: 32px;
    padding-bottom: 65px;
}

.projects-upcoming {
    padding-top: 65px;
    padding-bottom: 95px;
}

.projects-upcoming .ellipse22 {
    left: unset;
    right: 5%;
    top: 40%;
}

.projects-upcoming .ellipse23 {
    left: 28%;
    top: unset;
    bottom: 120px;
}

.projects-completed {
    padding-top: 95px;
    padding-bottom: 110px;
}

.project-page {
    padding-top: 70px;
}

.project-page > .loader {
    display: block;
    margin: 0 auto;
}

.details-section .ellipse19 {
    top: 20px;
    left: 80%;
}

.swap-section {
    margin-top: 80px;
    padding-bottom: 50px;
}

.swap-section .ellipse18 {
    width: 220px;
    height: 220px;
    left: 3%;
    top: -50%;
}

.swap-section .tile {
    padding: 40px;
}

.project-page .whitelist-block {
    margin-top: 40px;
}

.project-page .whitelist-block p {
    max-width: 370px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
}

.project-page .whitelist-block a:not(.round-button) {
    color: inherit;
}

.project-page .whitelist-block a:not(.round-button):hover {
    color: var(--color-text-white);
}

.project-page__wallet-info {
    margin-bottom: 40px;
}

@media (min-width: 1200px) {
    .project-page__wallet-info {
        padding-left: 44px;
        margin-bottom: 0;
    }
}

.whitelist-badge {
    display: inline-flex;
    padding: 24px;
    align-items: center;
    border-radius: 26px;
}

.whitelist-badge.invalid {
    border: none;
}

.whitelist-badge .loader {
    --loader-size: 32px;
}

.whitelist-badge img {
    margin-right: 16px;
}

.tier-status {
    font-size: 20px;
    line-height: 1.26;
    letter-spacing: 1px;
    margin-bottom: 16px;
}

.tier-status span {
    font-weight: 700;
    font-style: italic;
}

.swap-form {
    border-radius: 24px;
    padding: 46px;
}

.swap-form .form-message {
    margin-bottom: 24px;
}

.swap-form .input-group {
    margin-bottom: 24px;
}

.swap-form .input-group img {
    width: 48px;
}

.swap-form__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
}

.swap-form__buttons .btn {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
}

.swap-form__buttons .btn:not(:first-child) {
    margin-left: 26px;
}

.external-description__nft-image {
    width: 100%;
}

.external-description__nft-image img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
}

@media (max-width: 1199px) {
    .external-description__nft-image img {
        max-height: 600px;
    }
}

.external-description__nft-subtitle {
    font-size: 40px;
    font-weight: 600;
}

.claim-section {
    margin-top: 80px;
}

.claim-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
}

.claim-block__title {
    display: flex;
    align-items: center;
}

.claim-block__title h4 {
    font-size: 32px;
    line-height: 1.26;
    font-weight: 600;
}

.claim-block__title .dynamic-image {
    width: 90px;
    height: 90px;
    margin-right: 24px;
}

@media (max-width: 1199px) {
    .swap-form {
        padding: 24px;
    }
}

.account-page {
    text-align: left;
}

.account-page .heading-row {
    padding-top: 60px;
    padding-bottom: 0;
}

.account-page .account-nav {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-separator);
    border-radius: 4px;
}

@supports (gap: 64px) {
    .account-page .account-nav {
        grid-gap: 64px;
        gap: 64px;
    }
}

@supports not (gap: 64px) {
    .account-page .account-nav > * + * {
        margin-left: 64px;
    }
}

.account-nav .nav-link {
    position: relative;
    text-transform: none;
    font-size: 40px;
    line-height: 1.26;
    letter-spacing: 1px;
    padding: 16px 0;
    font-weight: 400;
}

.account-nav .nav-link.active {
    font-weight: 600;
    border: none;
}

.account-nav .nav-link.active:after {
    height: 6px;
    top: calc(100% - 3px);
    border-radius: 3px;
}

.kyc-section .ellipse23 {
    top: -150px;
    left: 85%;
    opacity: 1;
}

.kyc-section .kyc-badge__status p {
    font-size: 32px;
}


.stake-allocation-section {
    /* padding-top: 120px;
    padding-bottom: 120px; */
}

.stake-allocation-section .title {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.6;
}

.stake-allocation-section .bottom-description {
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 1px;
}

.stake-allocation-section .ellipse18 {
    left: 8%;
    top: 50px;
}

.stake-block.row {
    margin-top: 40px;
    margin-bottom: 86px;
    padding: 46px;
    align-items: center;
}

.stake-form {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-separator);
    border-radius: 70px;
    padding: 40px;
}

.stake-form .tab-content {
    flex: 1 0;
}

.stake-form__input-icon {
    height: 48px;
}

.stake-block .nav-tabs {
    border-bottom: 1px solid var(--color-separator);
    margin-bottom: 26px;
}

.stake-block .nav-tabs .nav-link {
    border-width: 3px;
    border-radius: 4px;
    padding: 0 0 0.5em;
    margin-right: 32px;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    text-transform: capitalize;
}

.stake-block .nav-tabs .nav-link:focus,
.stake-block .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.stake-block .nav-tabs .nav-link.active {
    border-color: transparent transparent var(--color-text-violet) transparent;
    background-color: transparent;
    color: var(--color-text-white);
    font-weight: 600;
}

.stake-block .input-group {
    margin-bottom: 26px;
}

.stake-block__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
}

@supports (gap: 16px) {
    .stake-block__buttons {
        grid-gap: 16px;
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .stake-block__buttons > * + * {
        margin-left: 16px;
    }
}

.stake-block__buttons .btn.round-button {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
}

.stake-form .form-message {
    margin-bottom: 17px;
}

@media (max-width: 1199px) {
    .stake-form {
        padding: 24px;
        border-radius: 50px;
    }

    .stake-block .nav-tabs .nav-link {
        margin-right: 16px;
        font-size: 24px;
    }
}

.account-staking {
    padding-top: 75px;
}

.account-staking .stake-allocation-section .ellipse18 {
    top: 21%;
}

.account-staking__stake-block {
    justify-content: space-between;
}

.stake-block .tooltip-wrapper {
    margin-left: 8px;
}

.stake-block__info .info-list {
    margin-bottom: 32px;
}

.stake-block__info .info-list__item {
    border: 1px solid var(--color-separator);
    border-radius: 26px;
    padding: 18px 32px;
}

.stake-block__info .info-list__item .value {
    font-size: 40px;
}

.account-staking__stake-block .balance-item {
    margin-bottom: 32px;
}

.account-staking__stake-block .balance-item__main {
    font-size: 26px;
}

.stake-form__heading {
    display: flex;
    align-items: center;
}

.stake-form__heading > div {
    margin-left: 12px;
}

.stake-form__heading p {
    margin-bottom: 3px;
}

.stake-form__heading .round-button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    padding: 5px;
}

.stake-form__heading {
    margin-bottom: 24px;
}

.account-staking .stake-form {
    height: 560px;
}

.stake-form .form-message {
    margin-bottom: auto;
}

.stake-form .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.stake-block-tab-unstake .stake-block__buttons {
    flex-wrap: wrap;
}

.stake-block-tab-unstake .stake-block__buttons .round-button {
    flex-basis: 100%;
}

.staking-stats {
    margin-top: 14px;
}

.staking-stats.info-list > div + div {
    margin-top: 24px;
}

.no-projects-tile {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.projects-page .title:first-child {
    position: relative;
    z-index: 110;
}

.projects-open {
    padding-top: 32px;
    padding-bottom: 65px;
}

.projects-upcoming {
    padding-top: 65px;
    padding-bottom: 95px;
}

.projects-upcoming .ellipse22 {
    left: unset;
    right: 5%;
    top: 40%;
}

.projects-upcoming .ellipse23 {
    left: 28%;
    top: unset;
    bottom: 120px;
}

.projects-completed {
    padding-top: 95px;
    padding-bottom: 110px;
}

.no-projects-tile {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.projects-page .title:first-child {
    position: relative;
    z-index: 110;
}

.projects-open {
    padding-top: 32px;
    padding-bottom: 65px;
}

.projects-upcoming {
    padding-top: 65px;
    padding-bottom: 95px;
}

.projects-upcoming .ellipse22 {
    left: unset;
    right: 5%;
    top: 40%;
}

.projects-upcoming .ellipse23 {
    left: 28%;
    top: unset;
    bottom: 120px;
}

.projects-completed {
    padding-top: 95px;
    padding-bottom: 110px;
}

.account-page {
    text-align: left;
}

.account-page .heading-row {
    padding-top: 60px;
    padding-bottom: 0;
}

.account-page .account-nav {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-separator);
    border-radius: 4px;
}

@supports (gap: 64px) {
    .account-page .account-nav {
        grid-gap: 64px;
        gap: 64px;
    }
}

@supports not (gap: 64px) {
    .account-page .account-nav > * + * {
        margin-left: 64px;
    }
}

.account-nav .nav-link {
    position: relative;
    text-transform: none;
    font-size: 40px;
    line-height: 1.26;
    letter-spacing: 1px;
    padding: 16px 0;
    font-weight: 400;
}

.account-nav .nav-link.active {
    font-weight: 600;
    border: none;
}

.account-nav .nav-link.active:after {
    height: 6px;
    top: calc(100% - 3px);
    border-radius: 3px;
}

.kyc-section .ellipse23 {
    top: -150px;
    left: 85%;
    opacity: 1;
}

.kyc-section .kyc-badge__status p {
    font-size: 32px;
}


.farm-allocation-section {
    /* padding-top: 120px;
    padding-bottom: 120px; */
}

.farm-allocation-section .title {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.6;
}

.farm-allocation-section .bottom-description {
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 1px;
}

.farm-allocation-section .ellipse18 {
    left: 8%;
    top: 50px;
}

.farm-block.row {
    margin-top: 40px;
    margin-bottom: 86px;
    padding: 46px;
    align-items: center;
}

.farm-form {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-separator);
    border-radius: 70px;
    padding: 40px;
}

.farm-form .tab-content {
    flex: 1 0;
}

.farm-form__input-icon {
    height: 48px;
}

.farm-block .nav-tabs {
    border-bottom: 1px solid var(--color-separator);
    margin-bottom: 26px;
}

.farm-block .nav-tabs .nav-link {
    border-width: 3px;
    border-radius: 4px;
    padding: 0 0 0.5em;
    margin-right: 32px;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    text-transform: capitalize;
}

.farm-block .nav-tabs .nav-link:focus,
.farm-block .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.farm-block .nav-tabs .nav-link.active {
    border-color: transparent transparent var(--color-text-violet) transparent;
    background-color: transparent;
    color: var(--color-text-white);
    font-weight: 600;
}

.farm-block .input-group {
    margin-bottom: 26px;
}

.farm-block__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
}

@supports (gap: 16px) {
    .farm-block__buttons {
        grid-gap: 16px;
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .farm-block__buttons > * + * {
        margin-left: 16px;
    }
}

.farm-block__buttons .btn.round-button {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
}

.farm-form .form-message {
    margin-bottom: 17px;
}

@media (max-width: 1199px) {
    .farm-form {
        padding: 24px;
        border-radius: 50px;
    }

    .farm-block .nav-tabs .nav-link {
        margin-right: 16px;
        font-size: 24px;
    }
}

.account-staking {
    padding-top: 75px;
}

.account-staking .farm-allocation-section .ellipse18 {
    top: 21%;
}

.account-staking__farm-block {
    justify-content: space-between;
}

.farm-block .tooltip-wrapper {
    margin-left: 8px;
}

.farm-block__info .info-list {
    margin-bottom: 32px;
}

.farm-block__info .info-list__item {
    border: 1px solid var(--color-separator);
    border-radius: 26px;
    padding: 18px 32px;
}

.farm-block__info .info-list__item .value {
    font-size: 40px;
}

.account-staking__farm-block .balance-item {
    margin-bottom: 32px;
}

.account-staking__farm-block .balance-item__main {
    font-size: 26px;
}

.farm-form__heading {
    display: flex;
    align-items: center;
}

.farm-form__heading > div {
    margin-left: 12px;
}

.farm-form__heading p {
    margin-bottom: 3px;
}

.farm-form__heading .round-button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    padding: 5px;
}

.farm-form__heading {
    margin-bottom: 24px;
}

.account-staking .farm-form {
    height: 560px;
}

.farm-form .form-message {
    margin-bottom: auto;
}

.farm-form .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.farm-block-tab-unfarm .farm-block__buttons {
    flex-wrap: wrap;
}

.farm-block-tab-unfarm .farm-block__buttons .round-button {
    flex-basis: 100%;
}

.staking-stats {
    margin-top: 14px;
}

.staking-stats.info-list > div + div {
    margin-top: 24px;
}

.project-bio__inner {
    display: flex;
}

.project-bio__image {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    margin-right: 24px;
}

.project-bio__image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.project-bio__title {
    font-size: 20px;
    line-height: 1.26;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
    margin-bottom: 16px;
}

.project-bio__text {
    font-size: 14px;
    line-height: 26px;
}

.whitelist-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 300px);
}

.whitelist-page > .loader {
  display: block;
  margin: 0 auto;
}

.whitelist-section {
  padding-bottom: 80px;
}

.whitelist-section .ellipse18 {
  top: -20%;
}

.whitelist-section .ellipse19 {
  left: 10%;
  top: 40%;
}

.whitelist-section .tile + .tile {
  margin-top: 24px;
}

.whitelist-section .tile:not(.project-bio) p {
  margin-bottom: 0;
}

.whitelist-page .project-info {
  margin-bottom: 40px;
}

.whitelist-page .project-info__description {
  font-size: 14px;
}

.whitelist-page .kyc-approved-mark {
  padding: 14px;
}

.whitelist-time .title {
  margin-bottom: 32px;
}

.whitelist-section .tile:not(.whitelist-time, .whitelist-form) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 26px;
  gap: 26px;
}

@supports (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form) {
    grid-gap: 26px;
    gap: 26px;
  }
}

@supports not (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form) > * + * {
    margin-left: 26px;
  }
}

.whitelist-section .round-button {
  margin: 0;
}

:is(.whitelist-tier, .whitelist-page .kyc-badge) .round-button {
  width: 253px;
  flex-shrink: 0;
}

.whitelist-tier .balance-item__icon {
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  object-fit: contain;
  object-position: center;
}

@media (max-width: 1399px) {
  :is(.whitelist-tier, .whitelist-page .kyc-badge) .round-button {
    width: 223px;
  }
}

.wallet-page {
  text-align: left;
}

.wallet-page .hero-row {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left !important;
}

.wallet-page .hero-row .title {
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 112%;
  color: #fff;
  text-align: left;
  padding-bottom: 30px;
}

.wallet-page .connect-row {
  position: relative;
  justify-content: center;
  padding: 80px 0;
}

.wallet-page .connect-row .column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wallet-page .connect-row .round-button {
  margin-top: 32px;
}

.wallet-page .connect-row .tile__main {
  margin-top: 60px;
}

.wallet-page .connect-row .icon {
  position: absolute;
  top: -75px;
}

.wallet-page .tile {
  position: relative;
}

:root {
  --color-text-white: #ffffff;
  --color-text-black: #181a1b;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-background: #45225c;
  --color-text-red: #fe4646;
  --color-text-yellow: #ffbb55;
  --color-text-success: #02c88d;
  --color-text-violet: #c58bff;
  --color-main-gradient-text: linear-gradient(
    108.68deg,
    #c4a2ff 4.9%,
    #ff9393 74.14%
  );

  --color-separator: #414347;
  --container-max-width: 100%;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(
    var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) -
      12px
  );
}

body {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0b0a09;
  background-image: url(/static/media/stars.7252ac73.svg);
  min-height: 100%;
  color: #ffffff;
  color: var(--color-text-white);
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

header {
  z-index: 3;
}

.page {
  flex: 1 0;
}

section {
  position: relative;
}

.background {
  pointer-events: none;
}

section .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

[class*='ellipse'] {
  position: absolute;
  border-radius: 50%;
  transform-origin: 50%;
  transition: 0.2s linear;
}

.ellipse18 {
  width: 367px;
  height: 367px;
  left: 66%;
  top: 0;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  -webkit-filter: blur(100px);
          filter: blur(100px);
}

.ellipse19 {
  width: 172px;
  height: 172px;
  left: 14%;
  top: 155px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  -webkit-filter: blur(90px);
          filter: blur(90px);
}

.ellipse20 {
  width: 522px;
  height: 522px;
  left: -12%;
  top: -230px;
  background: linear-gradient(199.01deg, #ff3939 11.79%, #ffd646 87.19%);
  opacity: 0.5;
  -webkit-filter: blur(150px);
          filter: blur(150px);
}

.ellipse21 {
  width: 155px;
  height: 155px;
  left: 15%;
  top: 33%;
  background: linear-gradient(180deg, #ff6939 0%, #ffa946 100%);
  opacity: 0.6;
  -webkit-filter: blur(75px);
          filter: blur(75px);
}

.ellipse22 {
  width: 155px;
  height: 155px;
  left: 53%;
  top: -55px;
  background: linear-gradient(180deg, #ffd264 0%, #ff8946 100%);
  opacity: 0.6;
  -webkit-filter: blur(75px);
          filter: blur(75px);
}

.ellipse23 {
  width: 181px;
  height: 181px;
  left: 81%;
  top: 10px;
  background: linear-gradient(
    180deg,
    #ff3939 0%,
    rgba(255, 114, 70, 0.26) 100%
  );
  opacity: 0.6;
  -webkit-filter: blur(80px);
          filter: blur(80px);
}

.ellipse47 {
  width: 367px;
  height: 367px;
  left: 42%;
  top: 80px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  -webkit-filter: blur(140px);
          filter: blur(140px);
}

.ellipse48 {
  width: 152px;
  height: 152px;
  left: 80%;
  top: 221px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  -webkit-filter: blur(120px);
          filter: blur(120px);
}

.ellipse72 {
  position: absolute;
  width: 152px;
  height: 152px;
  left: 80%;
  top: 58px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  -webkit-filter: blur(120px);
          filter: blur(120px);
}

.ellipse73 {
  width: 367px;
  height: 367px;
  left: -5%;
  top: 180px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  -webkit-filter: blur(140px);
          filter: blur(140px);
}

.ellipse74 {
  width: 367px;
  height: 367px;
  left: -37px;
  top: 19px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  -webkit-filter: blur(110px);
          filter: blur(110px);
}

.page h1.title {
  font-size: 64px;
  font-weight: 700;
  line-height: 112%;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 70px;
  z-index: 1;
}

@media (max-width: 1199px) {
  .page h1.title {
    font-size: 52px;
  }
}

h2.title,
h2.subtitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 126%;
}

a {
  color: #ffffff;
  color: var(--color-text-white);
  transition: 0.2s linear;
}

a:hover {
  color: #c58bff;
  color: var(--color-text-violet);
  text-decoration: none;
}

.red-text {
  color: #fe4646;
  color: var(--color-text-red);
}

.success-text {
  color: #02c88d;
  color: var(--color-text-success);
}

.purple-text {
  color: #c58bff;
  color: var(--color-text-violet);
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text--orange {
  background-image: linear-gradient(
    94.07deg,
    #ff6d43 2.02%,
    #fe5145 27.83%,
    #ff9b38 55.36%,
    #fe4b61 78.24%
  );
}

.gradient-text--purple {
  background-image: linear-gradient(
    108.68deg,
    #c4a2ff 4.9%,
    #ff9393 74.14%
  );
  background-image: var(--color-main-gradient-text);
}

.fw-semibold {
  font-weight: 600;
}

.text-big {
  font-size: 20px;
  line-height: 1.26;
}

.text-wide {
  letter-spacing: 1px;
}

.bordered {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border: 1px solid var(--color-text-white-transparantize);
}

.btn:focus {
  box-shadow: none !important;
}

.tile {
  padding: 40px;
  background-color: rgba(35, 40, 47, 0.4);
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px);
  border-radius: 32px;
  color: #ffffff;
  color: var(--color-text-white);
}

.tile--with-shadow {
  padding: 40px;
  background-color: rgba(35, 40, 47, 0.7);
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(80px);
          backdrop-filter: blur(80px);
  border-radius: 32px;
}

.tile__description {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.tile__main {
  font-weight: 600;
  font-size: 40px;
  line-height: 126%;
}

.info-list {
  font-size: 20px;
  line-height: 1.26;
  letter-spacing: 1px;
}

.info-list > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-list > div + div {
  margin-top: 16px;
}

.info-list .name {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.info-list .value {
  font-weight: 600;
  margin-bottom: 0;
}

.input-group {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border: 1px solid var(--color-text-white-transparantize);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 10px 16px;
  border-radius: 16px;
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-control,
.form-control:focus,
.form-control:active {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border: 1px solid var(--color-text-white-transparantize);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 16px;
  border-radius: 16px;
  box-shadow: none;
  color: #ffffff;
  color: var(--color-text-white);
}

.form-control[readonly] {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.form-check .invalid-feedback:empty {
  display: none;
}

.form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  background-color: #45225c;
  background-color: var(--color-background);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border: 2px solid var(--color-text-white-transparantize);
}

.form-check .form-check-input {
  float: none;
  flex-shrink: 0;
  margin: 0 15px 0 0;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0 0.25rem var(--color-text-white-transparantize);
}

.form-check-input:checked[type='checkbox'] {
  background-color: transparent;
  border-color: #c58bff;
  border-color: var(--color-text-violet);
}

.form-check-input:checked[type='checkbox'] {
  background-image: url(/static/media/checkbox-checked.58f53a21.svg);
}

.form-check-input:checked[type='radio'] {
  background: #c58bff;
  background: var(--color-text-violet);
  background-clip: content-box;
  padding: 1px;
  border-color: #c58bff;
  border-color: var(--color-text-violet);
}

.form-label {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.form-label,
.form-check-label {
  line-height: 1.26;
  letter-spacing: 1px;
}

.input-group-big {
  border-radius: 30px;
}

.input-group-big .form-control {
  font-size: 40px;
}

.input-group-medium {
  border-radius: 30px;
}

.input-group-medium .form-control {
  font-size: 20px;
}

.input-group-prepend {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.input-group-prepend img {
  margin-right: 12px;
}

.input-group-prepend span {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.input-group-append {
  margin-left: 16px !important;
}

.input-group .form-control {
  padding: 0;
  background: transparent;
  border: none;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
  text-align: right;
  line-height: 126%;
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 1399px) {
  .input-group-prepend span {
    font-size: 16px;
  }

  .input-group .form-control {
    font-size: 30px;
  }
}

.input-group .form-control::-webkit-outer-spin-button,
.input-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.input-group .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.form-message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
}

.form-message svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.form-message svg path {
  fill: #fe4646;
  fill: var(--color-text-red);
}

.form-message--warning {
  color: #fe4646;
  color: var(--color-text-red);
}

.navbar-inner {
  background: transparent;
}

.bn-notify-custom {
  transition: 0.2s linear;
}

.bn-notify-notification-link:hover {
  color: #ffffff;
  color: var(--color-text-white);
}

.bn-notify-notification-link:hover .bn-notify-notification-info-message {
  color: #c58bff;
  color: var(--color-text-violet);
}

.custom-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
  scrollbar-width: 6px;
  overflow-scrolling: touch;
  overflow: auto;
  -ms-scroll-chaining: none none;
      overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
}

.back-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;
}

.back-link .btn.round-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 12px;
}

.back-link .round-button svg {
  margin: 0;
}

.back-link__label {
  font-size: 20px;
}

