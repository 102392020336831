.partner-pwd-widget {
    max-width: 604px;
    margin: 10vh auto;
    position: relative;
}

.partner-pwd-widget__logo {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -60px;
    left: calc(50% - 60px);
}

.partner-pwd-widget__title {
    text-align: center;
    margin-top: 34px;
    margin-bottom: 16px;
}

.partner-pwd-widget__description {
    line-height: 1.5;
    letter-spacing: 1px;
}

.partner-pwd-form {
    margin-top: 32px;
}

.partner-pwd-form .round-button {
    width: 100%;
}
