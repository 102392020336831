.whitelist-status-view {
    flex-grow: 1;
    display: flex;
}

.whitelist-status-view .ellipse21 {
    left: 65%;
}

.whitelist-status-view .tile {
    position: relative;
    text-align: center;
    width: 600px;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.whitelist-status-view .tile__description {
    color: var(--color-text-white);
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
}

.whitelist-status-view .tile__main {
    margin-bottom: 16px;
}

.whitelist-status-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
}

.whitelist-status-content {
    max-width: 330px;
}
