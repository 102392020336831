.project-bio__inner {
    display: flex;
}

.project-bio__image {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    margin-right: 24px;
}

.project-bio__image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.project-bio__title {
    font-size: 20px;
    line-height: 1.26;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
    margin-bottom: 16px;
}

.project-bio__text {
    font-size: 14px;
    line-height: 26px;
}
