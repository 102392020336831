.project-info__head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;
}

.project-info__logo {
    width: 80px;
    height: 80px;
    margin-right: 24px;
}

.project-info__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

@supports (gap: 8px) {
    .project-info__tags {
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .project-info__tags > * + * {
        margin-left: 8px;
    }
}

.project-info__name {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
}

.project-info__subtitle {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    margin-bottom: 0;
}

.project-info__description {
    line-height: 1.5;
    letter-spacing: 1px;
}

.project-info__download-terms {
    display: block;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 1px;
    margin: 32px 0;
}

.project-info__links {
    display: flex;
    align-items: center;
}

@supports (gap: 12px) {
    .project-info__links {
        gap: 12px;
    }
}

@supports not (gap: 12px) {
    .project-info__links > * + * {
        margin-left: 12px;
    }
}

.project-info__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 50%;
    transition: .2s linear;
}

.project-info__link svg {
    width: 20px;
    height: 20px;
}

.project-info__link:hover {
    background: rgba(255, 255, 255, 0.35);
}

@media(max-width: 1199px) {
    .project-info__head {
        margin-bottom: 24px;
    }

    .project-info__logo {
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }

    .project-info__name {
        font-size: 18px;
    }
}
