.card.accordion span {
    display: block;
}

.card.accordion {
    font-size: 18px;
    background: #23282F;
    box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    margin-bottom: 1rem;
    text-align: left;
}

.card.accordion .collapse {
    padding-top: 0;
    line-height: 150%;
}

.card.accordion .card-header {
    border: 0;
    padding: 0;
}

.card.accordion .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100% !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-text-white);
    padding: 32px 36px;
}

.card.accordion .btn span {
    transition: 0.3s linear;
}

.card.accordion .btn.active {
    color: var(--color-text-violet);
}

.card.accordion .btn.active svg path {
    fill: var(--color-text-violet);
}

.card.accordion .btn.active span {
    transform: rotate(180deg);
}

.card.accordion .card-body {
    padding: 0 8rem 2.25rem 2.25rem;
}
