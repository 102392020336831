.asset-loading {
    position: relative;
}

.asset-loading:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
    z-index: 2;
}
