.account-page {
    text-align: left;
}

.account-page .heading-row {
    padding-top: 60px;
    padding-bottom: 0;
}

.account-page .account-nav {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-separator);
    border-radius: 4px;
}

@supports (gap: 64px) {
    .account-page .account-nav {
        gap: 64px;
    }
}

@supports not (gap: 64px) {
    .account-page .account-nav > * + * {
        margin-left: 64px;
    }
}

.account-nav .nav-link {
    position: relative;
    text-transform: none;
    font-size: 40px;
    line-height: 1.26;
    letter-spacing: 1px;
    padding: 16px 0;
    font-weight: 400;
}

.account-nav .nav-link.active {
    font-weight: 600;
    border: none;
}

.account-nav .nav-link.active:after {
    height: 6px;
    top: calc(100% - 3px);
    border-radius: 3px;
}

.kyc-section .ellipse23 {
    top: -150px;
    left: 85%;
    opacity: 1;
}

.kyc-section .kyc-badge__status p {
    font-size: 32px;
}


.farm-allocation-section {
    /* padding-top: 120px;
    padding-bottom: 120px; */
}

.farm-allocation-section .title {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.6;
}

.farm-allocation-section .bottom-description {
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 1px;
}

.farm-allocation-section .ellipse18 {
    left: 8%;
    top: 50px;
}

.farm-block.row {
    margin-top: 40px;
    margin-bottom: 86px;
    padding: 46px;
    align-items: center;
}

.farm-form {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-separator);
    border-radius: 70px;
    padding: 40px;
}

.farm-form .tab-content {
    flex: 1 0;
}

.farm-form__input-icon {
    height: 48px;
}

.farm-block .nav-tabs {
    border-bottom: 1px solid var(--color-separator);
    margin-bottom: 26px;
}

.farm-block .nav-tabs .nav-link {
    border-width: 3px;
    border-radius: 4px;
    padding: 0 0 0.5em;
    margin-right: 32px;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    text-transform: capitalize;
}

.farm-block .nav-tabs .nav-link:focus,
.farm-block .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.farm-block .nav-tabs .nav-link.active {
    border-color: transparent transparent var(--color-text-violet) transparent;
    background-color: transparent;
    color: var(--color-text-white);
    font-weight: 600;
}

.farm-block .input-group {
    margin-bottom: 26px;
}

.farm-block__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
}

@supports (gap: 16px) {
    .farm-block__buttons {
        gap: 16px;
    }
}

@supports not (gap: 16px) {
    .farm-block__buttons > * + * {
        margin-left: 16px;
    }
}

.farm-block__buttons .btn.round-button {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
}

.farm-form .form-message {
    margin-bottom: 17px;
}

@media (max-width: 1199px) {
    .farm-form {
        padding: 24px;
        border-radius: 50px;
    }

    .farm-block .nav-tabs .nav-link {
        margin-right: 16px;
        font-size: 24px;
    }
}
