.navbar-brand svg {
  height: 60px;
}

.nav-link {
  position: relative;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-text-white);
}

.nav-link:hover {
  color: var(--color-text-violet) !important;
}

.nav-link.active {
  border: none;
}

.nav-link.active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background-color: var(--color-text-violet);
  border-radius: 2px;
}

.center-items {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

nav {
  padding: 0 10%;
}

nav .btn {
  background: var(--color-text-white);
  border-radius: 50px;
  line-height: 38px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@supports (gap: 24px) {
  .navbar-collapse {
    gap: 24px;
  }
}

@supports not (gap: 24px) {
  .navbar-collapse > * + * {
    margin-left: 24px;
  }
}

@media (max-width: 1199px) {
  .navbar .nav-link {
    padding: 0.5rem;
  }
}
