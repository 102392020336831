@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap');

:root {
  --color-text-white: #ffffff;
  --color-text-black: #181a1b;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-background: #45225c;
  --color-text-red: #fe4646;
  --color-text-yellow: #ffbb55;
  --color-text-success: #02c88d;
  --color-text-violet: #c58bff;
  --color-main-gradient-text: linear-gradient(
    108.68deg,
    #c4a2ff 4.9%,
    #ff9393 74.14%
  );

  --color-separator: #414347;
  --container-max-width: 100%;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(
    var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) -
      12px
  );
}

body {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0b0a09;
  background-image: url('./assets/stars.svg');
  min-height: 100%;
  color: var(--color-text-white);
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

header {
  z-index: 3;
}

.page {
  flex: 1 0;
}

section {
  position: relative;
}

.background {
  pointer-events: none;
}

section .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

[class*='ellipse'] {
  position: absolute;
  border-radius: 50%;
  transform-origin: 50%;
  transition: 0.2s linear;
}

.ellipse18 {
  width: 367px;
  height: 367px;
  left: 66%;
  top: 0;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  filter: blur(100px);
}

.ellipse19 {
  width: 172px;
  height: 172px;
  left: 14%;
  top: 155px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  filter: blur(90px);
}

.ellipse20 {
  width: 522px;
  height: 522px;
  left: -12%;
  top: -230px;
  background: linear-gradient(199.01deg, #ff3939 11.79%, #ffd646 87.19%);
  opacity: 0.5;
  filter: blur(150px);
}

.ellipse21 {
  width: 155px;
  height: 155px;
  left: 15%;
  top: 33%;
  background: linear-gradient(180deg, #ff6939 0%, #ffa946 100%);
  opacity: 0.6;
  filter: blur(75px);
}

.ellipse22 {
  width: 155px;
  height: 155px;
  left: 53%;
  top: -55px;
  background: linear-gradient(180deg, #ffd264 0%, #ff8946 100%);
  opacity: 0.6;
  filter: blur(75px);
}

.ellipse23 {
  width: 181px;
  height: 181px;
  left: 81%;
  top: 10px;
  background: linear-gradient(
    180deg,
    #ff3939 0%,
    rgba(255, 114, 70, 0.26) 100%
  );
  opacity: 0.6;
  filter: blur(80px);
}

.ellipse47 {
  width: 367px;
  height: 367px;
  left: 42%;
  top: 80px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  filter: blur(140px);
}

.ellipse48 {
  width: 152px;
  height: 152px;
  left: 80%;
  top: 221px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  filter: blur(120px);
}

.ellipse72 {
  position: absolute;
  width: 152px;
  height: 152px;
  left: 80%;
  top: 58px;
  background: linear-gradient(180deg, #ff3939 0%, #ffeea2 100%);
  opacity: 0.8;
  filter: blur(120px);
}

.ellipse73 {
  width: 367px;
  height: 367px;
  left: -5%;
  top: 180px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  filter: blur(140px);
}

.ellipse74 {
  width: 367px;
  height: 367px;
  left: -37px;
  top: 19px;
  background: linear-gradient(180deg, #ffac2f 0%, #ff7246 100%);
  opacity: 0.8;
  filter: blur(110px);
}

.page h1.title {
  font-size: 64px;
  font-weight: 700;
  line-height: 112%;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 70px;
  z-index: 1;
}

@media (max-width: 1199px) {
  .page h1.title {
    font-size: 52px;
  }
}

h2.title,
h2.subtitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 126%;
}

a {
  color: var(--color-text-white);
  transition: 0.2s linear;
}

a:hover {
  color: var(--color-text-violet);
  text-decoration: none;
}

.red-text {
  color: var(--color-text-red);
}

.success-text {
  color: var(--color-text-success);
}

.purple-text {
  color: var(--color-text-violet);
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text--orange {
  background-image: linear-gradient(
    94.07deg,
    #ff6d43 2.02%,
    #fe5145 27.83%,
    #ff9b38 55.36%,
    #fe4b61 78.24%
  );
}

.gradient-text--purple {
  background-image: var(--color-main-gradient-text);
}

.fw-semibold {
  font-weight: 600;
}

.text-big {
  font-size: 20px;
  line-height: 1.26;
}

.text-wide {
  letter-spacing: 1px;
}

.bordered {
  border: 1px solid var(--color-text-white-transparantize);
}

.btn:focus {
  box-shadow: none !important;
}

.tile {
  padding: 40px;
  background-color: rgba(35, 40, 47, 0.4);
  backdrop-filter: blur(60px);
  border-radius: 32px;
  color: var(--color-text-white);
}

.tile--with-shadow {
  padding: 40px;
  background-color: rgba(35, 40, 47, 0.7);
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(80px);
  border-radius: 32px;
}

.tile__description {
  color: var(--color-text-white-transparantize);
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.tile__main {
  font-weight: 600;
  font-size: 40px;
  line-height: 126%;
}

.info-list {
  font-size: 20px;
  line-height: 1.26;
  letter-spacing: 1px;
}

.info-list > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-list > div + div {
  margin-top: 16px;
}

.info-list .name {
  font-weight: 400;
  color: var(--color-text-white-transparantize);
}

.info-list .value {
  font-weight: 600;
  margin-bottom: 0;
}

.input-group {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid var(--color-text-white-transparantize);
  backdrop-filter: blur(20px);
  padding: 10px 16px;
  border-radius: 16px;
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-control,
.form-control:focus,
.form-control:active {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid var(--color-text-white-transparantize);
  backdrop-filter: blur(20px);
  padding: 16px;
  border-radius: 16px;
  box-shadow: none;
  color: var(--color-text-white);
}

.form-control[readonly] {
  background: rgba(255, 255, 255, 0.08);
  color: var(--color-text-white-transparantize);
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.form-check .invalid-feedback:empty {
  display: none;
}

.form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  background-color: var(--color-background);
  border: 2px solid var(--color-text-white-transparantize);
}

.form-check .form-check-input {
  float: none;
  flex-shrink: 0;
  margin: 0 15px 0 0;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 0.25rem var(--color-text-white-transparantize);
}

.form-check-input:checked[type='checkbox'] {
  background-color: transparent;
  border-color: var(--color-text-violet);
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('./assets/checkbox-checked.svg');
}

.form-check-input:checked[type='radio'] {
  background: var(--color-text-violet);
  background-clip: content-box;
  padding: 1px;
  border-color: var(--color-text-violet);
}

.form-label {
  color: var(--color-text-white-transparantize);
}

.form-label,
.form-check-label {
  line-height: 1.26;
  letter-spacing: 1px;
}

.input-group-big {
  border-radius: 30px;
}

.input-group-big .form-control {
  font-size: 40px;
}

.input-group-medium {
  border-radius: 30px;
}

.input-group-medium .form-control {
  font-size: 20px;
}

.input-group-prepend {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.input-group-prepend img {
  margin-right: 12px;
}

.input-group-prepend span {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.input-group-append {
  margin-left: 16px !important;
}

.input-group .form-control {
  padding: 0;
  background: transparent;
  border: none;
  backdrop-filter: none;
  text-align: right;
  line-height: 126%;
  appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 1399px) {
  .input-group-prepend span {
    font-size: 16px;
  }

  .input-group .form-control {
    font-size: 30px;
  }
}

.input-group .form-control::-webkit-outer-spin-button,
.input-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control::placeholder {
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.form-message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
}

.form-message svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.form-message svg path {
  fill: var(--color-text-red);
}

.form-message--warning {
  color: var(--color-text-red);
}

.navbar-inner {
  background: transparent;
}

.bn-notify-custom {
  transition: 0.2s linear;
}

.bn-notify-notification-link:hover {
  color: var(--color-text-white);
}

.bn-notify-notification-link:hover .bn-notify-notification-info-message {
  color: var(--color-text-violet);
}

.custom-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
  scrollbar-width: 6px;
  overflow-scrolling: touch;
  overflow: auto;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: 0.2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
}

.back-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;
}

.back-link .btn.round-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 12px;
}

.back-link .round-button svg {
  margin: 0;
}

.back-link__label {
  font-size: 20px;
}
