.approval-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@supports (gap: 8px) {
    .approval-steps {
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .approval-steps > * + * {
        margin-left: 8px;
    }
}

.approval-steps__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 0.5;
}

.approval-steps__label.active {
    opacity: 1;
}

.approval-steps__line {
    width: 200px;
    height: 4px;
    border-radius: 3px;
    background: linear-gradient(90deg, #FFE68F 0%, #FFC061 33.36%, rgba(255, 144, 41, 0.5) 62.26%, rgba(254, 70, 70, 0) 100.49%);
}

.approval-steps__line.filled {
    background: linear-gradient(90deg, #FFE68F 0%, #FFC061 33.36%, #FF9029 62.26%, #FE4646 100.49%);
}
