.no-projects-tile {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.projects-page .title:first-child {
    position: relative;
    z-index: 110;
}

.projects-open {
    padding-top: 32px;
    padding-bottom: 65px;
}

.projects-upcoming {
    padding-top: 65px;
    padding-bottom: 95px;
}

.projects-upcoming .ellipse22 {
    left: unset;
    right: 5%;
    top: 40%;
}

.projects-upcoming .ellipse23 {
    left: 28%;
    top: unset;
    bottom: 120px;
}

.projects-completed {
    padding-top: 95px;
    padding-bottom: 110px;
}
