.whitelist-form .title {
    margin-bottom: 32px;
}

.whitelist-form .round-button {
    margin: 0;
}

.whitelist-form .form-row {
    justify-content: space-between;
}

.whitelist-form .form-row:not(.form-row + .form-row) {
    margin-top: 40px;
}

.whitelist-form .form-row .round-button {
    width: 216px;
    padding: 6px 0;
    flex-shrink: 0;
}

.whitelist-form .form-check {
    margin-right: 12px;
}

.whitelist-form .input-group {
    padding: 16px;
}

.whitelist-form .input-group.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.whitelist-form .input-group-prepend {
    margin-right: 2px;
}

.whitelist-form .input-group .form-control {
    text-align: left;
    font-size: 16px;
}

.whitelist-form .round-button[type="submit"] {
    width: 100%;
    margin-top: 16px;
}

.whitelist-form .round-button[type="submit"] svg {
    margin-top: -2px;
}

.whitelist-form .round-button[type="submit"] svg path {
    fill: var(--color-text-black);
}

@media (max-width: 1399px) {
    .whitelist-form .form-check-label {
        font-size: 14px;
    }
}
