.project-tag {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 20px;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: 500;
    line-height: 126%;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.project-tag__image {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

@media (max-width: 1199px) {
    .project-tag.badge {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.project-tag.coming-soon {
    background: linear-gradient(91.53deg, #29e6dc 1.3%, #dd976f 98.7%);
}

.project-tag.registration-open {
    background: linear-gradient(91.53deg, #a4b50b 1.3%, #8dc802 98.7%);
}

.project-tag.registration-closed {
    background: linear-gradient(91.53deg, #8d0bb5 1.3%, #48505C 98.7%);
}

.project-tag.private-open {
    background: linear-gradient(42.26deg, #386865 33.95%, #9946fe 138.93%);
}

.project-tag.open {
    background: linear-gradient(90deg, #46fe4f 0%, #67b12b 100%);
}

.project-tag.closed {
    background: linear-gradient(90deg, #851010 0%, #720e0e 100%);
}
