.timer-wrap {
    position: relative;
}

.timer-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 80;
    background-size: 100%;
    background-position: center;
    background-image: linear-gradient(178.08deg, rgba(196, 196, 196, 0) 1.57%, rgba(196, 196, 196, 0.0067) 13.92%, rgba(196, 196, 196, 0.01) 47.92%, rgba(196, 196, 196, 0.01) 81.29%, rgba(196, 196, 196, 0) 98.38%);
}

@supports (backdrop-filter: blur(20px)) {
    .timer-blur {

        backdrop-filter: blur(20px);
    }
}

@supports not (backdrop-filter: blur(20px)) {
    .timer-blur {
        background-color: rgba(27, 28, 31, 0.96);
    }
}

.timer-fog {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 90;
}

.timer-wrap .timer {
    max-width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}
