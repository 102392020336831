.whitelist-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 300px);
}

.whitelist-page > .loader {
  display: block;
  margin: 0 auto;
}

.whitelist-section {
  padding-bottom: 80px;
}

.whitelist-section .ellipse18 {
  top: -20%;
}

.whitelist-section .ellipse19 {
  left: 10%;
  top: 40%;
}

.whitelist-section .tile + .tile {
  margin-top: 24px;
}

.whitelist-section .tile:not(.project-bio) p {
  margin-bottom: 0;
}

.whitelist-page .project-info {
  margin-bottom: 40px;
}

.whitelist-page .project-info__description {
  font-size: 14px;
}

.whitelist-page .kyc-approved-mark {
  padding: 14px;
}

.whitelist-time .title {
  margin-bottom: 32px;
}

.whitelist-section .tile:not(.whitelist-time, .whitelist-form) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
}

@supports (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form) {
    gap: 26px;
  }
}

@supports not (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form) > * + * {
    margin-left: 26px;
  }
}

.whitelist-section .round-button {
  margin: 0;
}

:is(.whitelist-tier, .whitelist-page .kyc-badge) .round-button {
  width: 253px;
  flex-shrink: 0;
}

.whitelist-tier .balance-item__icon {
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  object-fit: contain;
  object-position: center;
}

@media (max-width: 1399px) {
  :is(.whitelist-tier, .whitelist-page .kyc-badge) .round-button {
    width: 223px;
  }
}
