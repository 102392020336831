.tooltip-wrapper {
    cursor: pointer;
}

.common-tooltip .tooltip-inner {
    background: linear-gradient(114.09deg, #000000 84.89%, rgba(0, 0, 0, 0) 99.69%);
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 26px;
    padding: 32px;
    max-width: 450px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
}
