.round-button {
    position: relative;
}

.round-button, .btn.round-button {
    font-family: Archivo !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: var(--color-text-white);
    color: #181A1B;
    border: 0;
    border-color: var(--color-text-white);
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
}

.round-button svg {
    height: 20px;
    margin-left: 5px;
}

.round-button .link-arrow {
    height: 1.2em;
    width: 1.2em;
}

.round-button .link-arrow path {
    fill: #181A1B;
    transition: .15s;
}

.round-button a, .btn.round-button a {
    color: inherit;
    text-decoration: none;
}

.round-button a:hover, .btn.round-button a:hover {
    color: inherit;
    text-decoration: none;
}

.round-button.dark {
    background: rgba(255, 255, 255, 0.15);
    color: var(--color-text-white);
    border-color: rgba(255, 255, 255, 0.15);
}

.round-button.dark .link-arrow path,
.round-button.transparent .link-arrow path {
    fill: var(--color-text-white);
}

.round-button.transparent {
    color: var(--color-text-white);
    background: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
}

.round-button:not(:disabled):hover, .btn.round-button:not(:disabled):hover {
    color: var(--color-text-violet);
}

.round-button:not(:disabled):hover .link-arrow path {
    fill: var(--color-text-violet);
}

.round-button.large {
    border-radius: 30px;
    line-height: 46px;
    padding-left: 30px;
    padding-right: 30px;
}

.round-button.small {
    line-height: 34px;
    padding-left: 16px;
    padding-right: 16px;
}

.round-button.wide {
    padding-left: 66px;
    padding-right: 66px;
}

@media (max-width: 1399px) {
    .round-button, .btn.round-button {
        font-size: 14px;
        line-height: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .round-button.large {
        line-height: 42px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .round-button.wide {
        padding-left: 55px;
        padding-right: 55px;
    }

    .round-button.small {
        line-height: 28px;
    }
}

.btn.round-button:disabled {
    pointer-events: auto;
    cursor: not-allowed;
}

.round-button.round-button--with-badge:disabled {
    opacity: 1;
}

.round-button__badge {
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 12px;
    line-height: 1.26;
    color: var(--color-background);
    padding: 6px 12px;
    background-color: var(--color-text-white);
    border: 2px solid var(--color-background);
    border-radius: 20px;
    text-transform: none;
    transform: rotate(-3.93deg);
}
