.project-widget {
    transition: .2s linear;
    height: 100%;
}

.project-widget__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.project-widget:hover {
    background-color: #23282F;
    box-shadow: none !important;
}

.project-widget .project-info {
    margin-bottom: 10px;
}

.project-widget .project-info__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-widget .project-stats {
    margin-bottom: auto;
}

.project-widget__buttons {
    display: flex;
    justify-content: center;
}

@supports (gap: 17px) {
    .project-widget__buttons {
        gap: 17px;
    }
}

@supports not (gap: 17px) {
    .project-widget__buttons > * + * {
        margin-left: 17px;
    }
}

.project-widget__buttons a {
    max-width: calc(50% - 8px);
    flex-grow: 1;
}

.project-widget__buttons .round-button {
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 10px;
}

@media (max-width: 1199px) {
    .project-widget.tile {
        padding: 32px;
    }

    .project-widget .project-info {
        margin-bottom: 24px;
    }

    .project-widget__buttons {
        margin-top: 24px;
    }
}
