.project-stats.tile {
  padding: 40px;
}

.project-stats__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.project-stats__status h3.title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.26;
}

.project-stats__progress {
  margin-bottom: 16px;
}

.project-stats__progress .progress {
  background: rgba(255, 255, 255, 0.2);
  overflow: unset;
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.project-stats__progress .progress-bar {
  background: linear-gradient(89.94deg, #32595c 0.05%, #19caa4 99.95%);
  box-shadow: 0px 0px 20px rgba(61, 84, 194, 0.5);
  border-radius: 10px;
}

.project-stats__progress .percentage {
  display: block;
  text-align: right;
  font-size: 20px;
  line-height: 1.26;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 7px;
}

.project-stats__list {
  margin-bottom: 24px;
}

@media (max-width: 1400px) {
  .project-stats__list {
    font-size: 16px;
  }
}

.project-stats__list .value {
  white-space: nowrap;
}

.project-stats__info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: 1px;
}

.project-stats__info dt {
  font-weight: 400;
  color: var(--color-text-white-transparantize);
}

.project-stats__info dd {
  font-weight: 600;
  margin-bottom: 0;
}

.stats-block {
  padding: 16px 24px;
  text-align: center;
  /* border: 1px solid var(--color-separator); */
  flex-basis: calc(50% - 8px);
  border-radius: 24px;
}

.stats-block dt {
  margin-bottom: 6px;
}

.stats-block--double {
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-block--double .separator {
  height: 100%;
  width: 1px;
  margin: 0 24px;
  background-color: var(--color-separator);
}

.stats-block--double > div:not(.separator) {
  flex-grow: 1;
}

.apply-white-list {
  display: flex;
  justify-content: center;
}

@media (max-width: 1399px) {
  .project-stats__info {
    font-size: 14px;
  }

  .stats-block {
    padding: 16px;
  }
}

@media (max-width: 1199px) {
  .project-stats__status {
    margin-bottom: 16px;
  }

  .project-stats__status h3.title {
    font-size: 32px;
  }
}
